// store
import { merge } from "lodash-es";
import { makeAutoObservable } from "mobx";

import { MicroAppGlobalDataProps } from "@/microapp/microapp-types";
/**
 * 微前端全局数据源
 */
const microappGlobalDataStore = makeAutoObservable({
  data: {
    microAppConfig: {
      theme: {
        componentSize: undefined,
        componentDisabled: undefined,
        primaryColor: undefined,
        borderRadius: undefined,
        isDark: undefined,
        isCompact: undefined,
      },
    },
    user: {},
    isLogin: false,
  } as MicroAppGlobalDataProps,
  changeGlobalData: (data: Partial<MicroAppGlobalDataProps>) => {
    const newData = merge(microappGlobalDataStore.data, data);
    microappGlobalDataStore.data = newData;
  },
});

export default microappGlobalDataStore;
