import "driver.js/dist/driver.css";

import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

ReactDOM.createRoot(
  document.getElementById("backend-module-system") as HTMLElement,
).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
