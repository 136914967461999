import { SizeType } from "antd/es/config-provider/SizeContext";

export interface MicroAppGlobalDataProps {
  microAppConfig: {
    theme: {
      primaryColor?: string;
      borderRadius?: number;
      componentSize?: SizeType;
      componentDisabled?: boolean;
      isDark?: boolean;
      isCompact?: boolean;
      [key: string]: any;
    };
  };
  user: Record<string, any>;
  [key: string]: any;
}
/** 微前端 子应用 */
export const enum MicroAppModuleNameEnum {
  MODULE_SYSTEM = "backend-module-system",
}
