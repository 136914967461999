import { observer } from "mobx-react-lite";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";
import {
  MicroAppGlobalDataProps,
  MicroAppModuleNameEnum,
} from "@/microapp/microapp-types";

import { useMicroAppGlobalDataListener } from "./hooks";

/**
 * ## 配置监听组件
 *
 * **监听基座配置变更，触发子应用更新。**
 *
 */
function BaseAppGlobalDataListening() {
  // 监听基座下发数据变化
  const onChange = (data: MicroAppGlobalDataProps) => {
    microappGlobalDataStore.changeGlobalData(data);
  };
  // 监听基座下发的数据变化
  useMicroAppGlobalDataListener(onChange);

  return (
    <div
      id={`BaseAppGlobalDataListening-${MicroAppModuleNameEnum.MODULE_SYSTEM}`}
    />
  );
}

export default observer(BaseAppGlobalDataListening);
