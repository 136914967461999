import { MicroAppModuleNameEnum } from "@/microapp/microapp-types";

import BaseAppGlobalDataListening from "./BaseAppGlobalDataListening";
import BaseAppRouterListening from "./BaseAppRouterListening";

/**
 * ## 子应用初始化组件
 *
 * **template**
 */
function MicroAppInit() {
  return (
    <div id={`MicroAppInit-${MicroAppModuleNameEnum.MODULE_SYSTEM}`}>
      <BaseAppGlobalDataListening />
      <BaseAppRouterListening />
    </div>
  );
}
export default MicroAppInit;
