import { useNavigate } from "react-router-dom";

import {
  MicroAppGlobalDataProps,
  MicroAppModuleNameEnum,
} from "@/microapp/microapp-types";

import { useMicroAppDataListener } from "./hooks";

/**
 * ### 路由监听组件
 *
 * 监听基座路由变更，触发子应用路由跳转。
 *
 * 其他子应用会触发改变其路由，每个子应用需兜底404。
 */
function BaseAppRouterListening() {
  const navigate = useNavigate();

  // 监听基座下发的数据变化
  const onChange = (data: MicroAppGlobalDataProps) => {
    alert(`基座下发信息${JSON.stringify(data)}`);
    console.log(`基座下发信息${JSON.stringify(data)}`);
    const path = data?.path;
    navigate(path);
  };
  useMicroAppDataListener(onChange);

  return (
    <div
      id={`BaseAppRouterListening-${MicroAppModuleNameEnum.MODULE_SYSTEM}`}
    />
  );
}

export default BaseAppRouterListening;
