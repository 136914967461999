// import { Spin } from "antd";
// import { lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";

import MicroAppInit from "./microapp/microapp-components/microapp-init";

// 延迟加载组件
// 延迟加载
// function LazyComponent({ path }: { path: string }) {
//   const RenderComponent = lazy(() => import(path));

//   return (
//     <Suspense fallback={<div className="flex h-full w-full flex-col items-center justify-center"><Spin spinning /></div>}>
//       <RenderComponent />
//     </Suspense>
//   );
// }
function RouterRoot() {
  return (
    <>
      <MicroAppInit />
      <Outlet />
    </>
  );
}

const routers: RouteObject[] = [
  {
    path: "/",
    element: <RouterRoot />,
    children: [
      {
        path: "/welcome",
        lazy: async () => ({
          Component: (await import("./pages/welcome")).default,
        }),
      },
      {
        path: "/oss",
        lazy: async () => ({
          Component: (await import("./pages/oss")).default,
        }),
      },
      {
        path: "/user",
        lazy: async () => ({
          Component: (await import("./pages/user")).default,
        }),
      },
      {
        path: "/setting",
        lazy: async () => ({
          Component: (await import("./pages/setting")).default,
        }),
      },
      {
        path: "/record",
        lazy: async () => ({
          Component: (await import("./pages/record")).default,
        }),
      },
      {
        path: "/lower-code-development-platform",
        lazy: async () => ({
          Component: (await import("./pages/lower-code-development-platform"))
            .default,
        }),
      },
      {
        path: "/",
        lazy: async () => ({
          Component: (await import("./pages/welcome")).default,
        }),
      },
    ],
  },
];
export default routers;
