import "@/common/styles/globals.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import routers from "./router";

function App() {
  const router = createBrowserRouter(routers);
  return <RouterProvider router={router} />;
}

export default App;
